import React from "react";
import Call from "../components/Call";
import Layout from "../components/Layout";

function Impressum() {
  return (
    <Layout bodyClass="page-default-single">
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 ">
            <h1 id="impressum">Impressum</h1>

            <h2 id="angaben-gem-5-tmg">Angaben gemäß § 5 TMG</h2>
            <p>
              Juliane Bartelt
              <br />
              freiberufliche Logopädin
              <br />
              Ulzburger Straße 356b
              <br />
              22846 Norderstedt
            </p>

            <h2 id="kontakt">Kontakt</h2>
            <Call />
            <br />

            <h2 id="berufsbezeichnung-und-berufsrechtliche-regelungen">Berufsbezeichnung und berufsrechtliche Regelungen</h2>
            <p>
              Berufsbezeichnung: Logopädin
              <br />
              Zuständige Kammer: Landesamt für Gesundheit und Soziales
              <br />
              Verliehen in: Mecklenburg Vorpommern
            </p>
            {/*
                Es gelten folgende berufsrechtliche Regelungen:
                - 123
                - 456
              */}

            <h2 id="redaktionell-verantwortlich">Redaktionell verantwortlich</h2>
            <p>Juliane Bartelt</p>

            <h2 id="verbraucherstreitbeilegung-universalschlichtungsstelle">Verbraucherstreitbeilegung / Universalschlichtungsstelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

            <h3 id="quelle-">Quelle:</h3>
            <p>
              <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Impressum;
